import packageJson from "../../../package.json";
const env = process.env.REACT_APP_ENVIRONMENT;
const constants = {
  appName: "eConnect",
  appVersion: packageJson.version,
  fileStoragePath: process.env.REACT_APP_FILE_STORAGE_PATH,
};

const dev_config = {
  api_url: process.env.REACT_APP_API_BASE_URL_DEV, // "https://ciplacmsdevapi.clirdev.com/api",
  api_base_url: process.env.REACT_APP_API_DOMAIN_DEV,
  doctube_url: process.env.REACT_APP_DOCTUBE_URL_DEV,
  upload_file_destination: process.env.REACT_APP_FILE_STORAGE_PATH,
};
const uat_config = {
  api_url: process.env.REACT_APP_API_BASE_URL_UAT,
  api_base_url: process.env.REACT_APP_API_DOMAIN_UAT,
  doctube_url: process.env.REACT_APP_DOCTUBE_URL_UAT,
  upload_file_destination: process.env.REACT_APP_FILE_STORAGE_PATH,
};
const pre_prod_config = {
  api_url: process.env.REACT_APP_API_BASE_URL_PRE_PROD,
  api_base_url: process.env.REACT_APP_API_DOMAIN_PRE_PROD,
  doctube_url: process.env.REACT_APP_DOCTUBE_URL_PRE_PROD,
  upload_file_destination: process.env.REACT_APP_FILE_STORAGE_PATH,
};
const prod_config = {
  api_url: process.env.REACT_APP_API_BASE_URL_PROD,
  api_base_url: process.env.REACT_APP_API_DOMAIN_PROD,
  doctube_url: process.env.REACT_APP_DOCTUBE_URL_PROD,
  upload_file_destination: process.env.REACT_APP_FILE_STORAGE_PATH_PROD,
};
const environment = () => {
  switch (env) {
    case "1":
      return dev_config;
    case "2":
      return uat_config;
    case "3":
      return pre_prod_config;
    case "4":
      return prod_config;

    default:
      return "";
  }
};
const app_config = {
  ...constants,
  ...environment(),
};
export default app_config;
